import React, { useState, useEffect } from 'react';
import '../../components/pages/Login/LoginScreen.css';
import Header from '../../components/pages/common/header';
import Container from '../container';
import { editProfileContainer } from "../../api/api"; 
import ToastMsg from './common/ToastMsg';
import { useNavigate } from 'react-router-dom';

const EditProfileScreen = () => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    
    

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');

        try {
            const response = await editProfileContainer(profileData.firstName, profileData.phone, token);
            console.log('Profile updated:', response);
            ToastMsg.success('Profile updated successfully!'); 
            navigate('/dashboard');
        } catch (error) {
            console.error('Error updating profile:', error);
            ToastMsg.error(error.response.data.message); 
        }
    };

    return (
        <Container>
            <div style={{width: "100%"}} className="edit-profile-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="edit-profile-title">Edit Profile</h2>
                <form className="edit-profile-form mr-20" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter Name"
                            value={profileData.firstName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={profileData.lastName}
                            onChange={handleInputChange}
                            required
                        />
                    </div> */}
                    {/* <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={profileData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone"
                            value={profileData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="verify-button2">Save Changes</button>
                </form>
            </div>
        </Container>
    );
};

export default EditProfileScreen;
