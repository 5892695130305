import React, { useState } from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import PlatformFeesicon from '../../../assets/images/platformimg.png'; 
import Container from '../../container';
import { createOrder } from '../../../api/api';  
import ToastMsg from '../common/ToastMsg.js';  

const PlatformFees = () => {
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

    const handlePaymentClick = () => {
        setIsPaymentProcessing(true);  

        const depositAmount = 499;  
        const token = localStorage.getItem('access_token'); 
        const userId = localStorage.getItem('user_id'); 

        const payload = {
            amount: depositAmount,  
        };

        createOrder(payload, token)  
            .then((data) => {
                console.log('Order created successfully:', data);
                ToastMsg.success('Order created successfully! Get ready to enjoy our services.');
                setIsPaymentProcessing(false);
            })
            .catch((error) => {
                console.error('Error creating order:', error);
                ToastMsg.error('Error processing payment. Please try again!');
                setIsPaymentProcessing(false);
            });
    };

    return (
        <Container>
            <div className="platform-fees-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="verify-titlee">Platform Fees</h2>
                <div className="instructions-container">
                    <p className="verify-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                    <p className="verify-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                </div>
                <div className="payment-container">
                    <div className="payment-methods">
                        <img src={PlatformFeesicon} alt="platformfee" className="payment-icon" />
                    </div>
                </div>
            </div>

            
            <button 
                className="verify-button" 
                onClick={handlePaymentClick}
                disabled={isPaymentProcessing} 
            >
                {isPaymentProcessing ? 'Processing...' : 'Pay ₹499'}
            </button>
        </Container>
    );
};

export default PlatformFees;
