import React, { useState, useEffect,useCallback } from 'react';
import Slider from 'react-slick';
import '../Login/LoginScreen.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from '../common/header';
import userIcon from '../../../assets/images/user.png';
import referIcon from '../../../assets/images/rupee.png';
import depositIcon from '../../../assets/images/deposit.png';
import viewsIcon from '../../../assets/images/supereye.png';
import partnerImage from '../../../assets/images/partner.png';
import errorIcon from '../../../assets/images/error.png';
import { useNavigate } from 'react-router-dom';
import { dashboardApi, fetchPartnerData, addReward, fetchUserdetail } from '../../../api/api';
import ReactPlayer from 'react-player';
import paisebnaotext from '../../../assets/images/paisebnaotext.png'
import Container from '../../container';
import MemberShipPlan from "./membership.js";
import tresery from "../../../assets/images/chestmoney.png"
import earncash from '../../../assets/images/newCash.png';
import earcchest from '../../../assets/images/earnChest.png';
import Play from '../../../assets/images/play.png'
import Pause from '../../../assets/images/pause.png'
import ToastMsg from '../common/ToastMsg.js'; 

const Dashboard = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [dashboardData, setDashboardData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [pointsEarned, setPointsEarned] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [withdrawalPopupVisible, setWithdrawalPopupVisible] = useState(false);
    const [name, setname] = useState("User");
    const [totalViews, setTotalViews] = useState(10);
    const [renderUi, setRenderUi] = useState(false);
    const [videoPlayUrl, setVideoPlayUrl] = useState(null);
    const [rewardAdded, setRewardAdded] = useState(false);
    const [videoCompleted, setVideoCompleted] = useState(false);
    const [referralId, setReferralId] = useState(null);
    const [referralCount, setreferralCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [membershipViewPoints , setMembershipViewPoints] = useState(0)
  

   const token= localStorage.getItem('access_token');    
    const navigate = useNavigate();

    const handleVideoReady = () => {
        setIsLoading(false);
      };
    
      const fetchDashboardData = useCallback(async () => {
        try {
            const response = await dashboardApi();
            console.log(response.data, "Dashboard API response");
    
            if (response.data && Array.isArray(response.data)) {
                setDashboardData(response.data);
                setRenderUi(true);
                // ToastMsg.success('Dashboard data fetched successfully!'); 
            } else {
                console.error('Invalid data format received from dashboard API');
                // ToastMsg.error('Invalid data format received.'); 
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            // ToastMsg.error('Failed to fetch dashboard data.'); 
        }
    }, [dashboardApi]);
    
    

    
    const handlefetchPartnersData = async () => {
        try {
            const data = await fetchPartnerData();
            console.log(data, "Partner API response");
    
            if (data && Array.isArray(data)) {
                setDashboardData(data);
                // ToastMsg.success('Partner data fetched successfully!'); 
            } else {
                console.error('Invalid partner data format received.');
                // ToastMsg.error('Invalid partner data format received.'); 
            }
        } catch (error) {
            console.error('Error fetching partner data:', error);
            // ToastMsg.error('Failed to fetch partner data.'); 
        }
    };
    
    const handlefetchUserdetail = async () => {
        try {
            const data = await fetchUserdetail();
            console.log(data, "user API response");
    
            if (data && data.data) {
                setreferralCount(data.data.referral_count);
                setReferralId(data.data.referral_id);
                setname(data.data.first_name?.split(' ')[0]);
                setPointsEarned(data.data.points); 
                setTotalViews(data.data.views);
                setMembershipViewPoints(data.data.membership__video_point)
            } else {
                console.error('Failed to fetch user details.');
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleRedirect = () => {
        navigate('/profile');
    };

    const handleRefernearn = () => {
        navigate(`/referandearn?points-earned=${formatCurrency(pointsEarned)}&referralId=${referralId}&referralCount=${referralCount}`);

    };

    const handleDeposit = () => {
        navigate('/deposit-fee');
    };

    const handleAmountWithdrawal = () => {
        if (pointsEarned < 500) {
            setWithdrawalPopupVisible(true);
        } else {
            navigate(`/amount-withdraw?balance=${pointsEarned}`);
        }
    };

    const handleRetry = () => {
        alert('Retrying payment...');
        setIsVisible(false);
    };

   

    useEffect(() => {
        fetchDashboardData();
        handlefetchPartnersData();
        handlefetchUserdetail();
      }, [fetchDashboardData]);
    
     
     
      const handlePlayButtonClick = () => {
        if(currentVideoIndex < dashboardData?.length -1){
            setIsPlaying(prevIsPlaying => !prevIsPlaying);
    }
      };
   
      useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        const checkDataAndRefresh = () => {
            if (dashboardData.length > 0) {
                if (currentVideoIndex < dashboardData.length) {
                    const currentLink = dashboardData[currentVideoIndex]?.redirection_link;
                    if (!currentLink) {
                        console.error('Invalid video link, refreshing the page');
                        window.location.reload(); 
                    } else {
                        setIsPlaying(true);
                        setRewardAdded(false);
                        setVideoCompleted(false);
                    }
                }
            } else {
                console.error('Dashboard data is empty, refreshing the page');
                window.location.reload(); 
            }
        };

        const delay = setTimeout(checkDataAndRefresh, 2000); 

        return () => clearTimeout(delay);
    }, [dashboardData, currentVideoIndex ]);


    
    useEffect(() => {
      setVideoCompleted(false);
      setRewardAdded(false);
  }, [currentVideoIndex]);

  const handleAddReward = async (id) => {
    try {
        const data = await addReward(id);
        console.log(data, "add reward");

        if (data && data.data.message) {
            setRewardAdded(true);
            // ToastMsg.success('Reward added successfully!'); 
        } else {
            console.error('Failed to add reward.');
            // ToastMsg.error('Failed to add reward. Please try again.'); 
        }
    } catch (error) {
        console.error('Error adding reward:', error);
        // ToastMsg.error('Error adding reward.'); 
    }
};

  const handleProgress = (state) => {
    const videoDuration = dashboardData[currentVideoIndex]?.timer || 0;
    const playedRatio = state.playedSeconds / videoDuration;
    setPlayed(playedRatio);
    setCurrentTime(state.playedSeconds);

    if (state.playedSeconds >= videoDuration && !videoCompleted) {
        setIsPlaying(false);
        setVideoCompleted(true);

        setTimeout(() => {
            setPopupVisible(true);

            if (!rewardAdded) {
                handleAddReward(dashboardData[currentVideoIndex]?.id);
                setRewardAdded(true);
            }

            setTimeout(() => {
                setPopupVisible(false);
                setIsPlaying(false);
                handleNextVideo();
                setIsPlaying(false);
            }, 3000);
        }, 1000); 
    }
};



const handleNextVideo = () => {
 

    if (currentVideoIndex < dashboardData.length - 1) {
      setCurrentVideoIndex(prevIndex => prevIndex + 1);
      setIsPlaying(false); 
    } else {
      setIsPlaying(false);
    }
  };

 
  useEffect(() => {
    console.log(isPlaying, "is playing changed toggle");
  }, [isPlaying]);



const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-IN', { 
      style: 'currency', 
      currency: 'INR',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0  
  }).format(amount);
};
  
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false 
    };

    console.log(dashboardData[currentVideoIndex]?.redirection_link, "link current");
    
    return (
        <Container>
        <div className="dashboard-container">
            {isVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <button className="close-button" onClick={handleClose}>×</button>
                        </div>
                        <div className="popup-content">
                            <img src={errorIcon} alt="error" className="error-icon" />
                            <h2 className="popup-title">Payment Failed</h2>
                            <button className="retry-button" onClick={handleRetry}>Retry</button>
                        </div>
                    </div>
                </div>
            )}

          
{popupVisible && (
  <div className="popup-overlay">
    <div className="popup-container">
      <div className="popup-header">
        <button className="close-button" onClick={() => setPopupVisible(false)}>×</button>
      </div>
      <div className="popup-content">
        <img width={200} src={tresery} alt="Congratulations" className="popup-image" />
        <h2 className="popup-title">Congratulations!!</h2>
        <p className="popup-message">You Earned ₹{membershipViewPoints}</p>
      </div>
    </div>
  </div>
)}


            {withdrawalPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <button className="close-button" onClick={() => setWithdrawalPopupVisible(false)}>×</button>
                        </div>
                        <div className="popup-content">
                            <h2 className="popup-title">Minimum Withdrawal Amount</h2>
                            <p>You need to have at least ₹500 to withdraw.</p>
                        </div>
                    </div>
                </div>
            )}

            

            
                <div className="top-buttons ">
                    <button onClick={handleRedirect} className="top-button user">
                        <img src={userIcon} alt="User" className="icon" /> Hi, {name}
                    </button>
                    <button onClick={handleRefernearn} className="top-button refer-earn">
                        <img src={referIcon} alt="Refer & Earn" className="icon" /> Refer & Earn
                    </button>
                    <button onClick={handleDeposit} className="top-button deposit">
                        <img src={depositIcon} alt="Deposit" className="icon" /> Deposit
                    </button>
                </div>

                

                <>
                    <div className="account-info">
                        <div className='flex-row'> 
                            <span className='f-400'>welcome to </span>
                            <img src={paisebnaotext} alt="paisebnaotextlogo" className="smalltextlogoone ml-7 mr-2" />
                            <span className='f-400'> <span className='bold' >App</span> watch & Earn</span>
                        </div>
                        <div className='flex-row'> 
                        <div className="views-info view-bg">
                                <img src={viewsIcon} alt="Views" className="views-icon" />
                                <div className="points-display-wrapper">
                                    {isLoading ? (
                                        <div className="text-skeleton-loader"></div>
                                    ) : (
                                        <span className="mt-10">{totalViews} Views</span>
                                    )}
                                </div>
                            </div>

                            <div className="balance-info">
                                <div className="balance-amount flex-col">
                                <div className="points-display-wrapper">
                                    {isLoading ? (
                                        <div className="text-skeleton-loader"></div>
                                    ) : (
                                        <span style={{ color: "white" }}>
                                        {formatCurrency(pointsEarned)}
                                        </span>
                                    )}
                                    </div>
                                    <span className='f12-white'>Account Balance</span>
                                </div>
                                <button onClick={handleAmountWithdrawal} className="withdraw-button">Withdraw</button>
                            </div>
                        </div>
                    </div>

                    <MemberShipPlan />
                    <div className="video-section">
                <h3 className="video-title">View this video and get ₹{membershipViewPoints }</h3>
                <div className="video-player">
                
            <div className="video-player-wrapper">
                {isLoading && <div className="skeleton-loader"></div>}
                <div className={`video-player ${isLoading ? 'hidden' : 'fade-in'}`}>
                <ReactPlayer
                    url={dashboardData[currentVideoIndex]?.link}
                    playing={isPlaying}
                    controls={false}
                    playbackRate={1}
                    onProgress={handleProgress}
                    width="100%"
                    height="100%"
                    onReady={handleVideoReady}
                    style={{ pointerEvents: 'none' }}
                />
                </div>
                </div>
                    
                    <button onClick={handlePlayButtonClick} className="play-button ">
                    {isPlaying ?
                    <img src={Pause} alt="Play Icon" className="play-icon" />
                    : 
                    <img src={Play} alt="Play Icon" className="play-icon" />
                    }
                    </button>
                
                </div>
     

                <div className="progress-bar-container">
                    <div className="progress-bar">
                    <div className="progress" style={{ width: `${played * 100}%` }}></div>
                    </div>
                    <img className="progress-image" width={43} src={earcchest} />
                </div>
                <div style={{display: "flex"}} className='flexrownew'>
                <div className="video-details">
                    <p>Watch full video and earn</p>
                    <img width={21} height={21} src={earncash}/>
                </div>
                <div className="video-timer">
                    <p><span className='red'>{formatTime(currentTime)}</span> / {formatTime(dashboardData[currentVideoIndex]?.timer)}</p>
                </div>
                </div>
                </div>
                </>
         
        </div>
        </Container>
    );
};

export default Dashboard;
