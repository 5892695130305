import React from 'react';
import './Login/LoginScreen.css'; 
import Header from './common/header';
// import helpIcon from '../../../assets/images/help.png';  // Assuming you have a help icon image
import Container from '../../components/container';

const HelpCenterScreen = () => {
    const handleContactSupport = () => {
        
        window.location.href = "mailto:paisebnao1111@gmail.com";
    };

    const handleCallSupport = () => {
        window.location.href = "tel:9769335392";
    };

    return (
        <Container>
            <div className="help-center-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <div className="help-icon-container mt-20">
                    {/* <img src={helpIcon} alt="help" className="help-icon" /> */}
                </div>
                <h2 className="help-title">Help Center</h2>
                <div className="instructions-container">
                    <p className="help-instructions">
                        <strong>Contact Us:</strong> If you have any issues or queries, feel free to reach out to us using the following methods:
                    </p>
                    <p className="help-instructions">
                        <strong>Email Support:</strong> You can contact our support team via email at <a href="mailto:paisebnao1111@gmail.com">paisebnao1111@gmail.com</a>.
                    </p>
                    <p className="help-instructions">
                    <strong>Text Support:</strong> Message us at <a href="https://wa.me/9769335392?text=">click here</a> for immediate assistance.

                    </p>
                    <p className="help-instructions">
                        <strong>FAQs:</strong> Visit our FAQ section to find answers to common queries.
                    </p>
                </div>

                <div className="help-button-container">
                    <button onClick={handleContactSupport} className="verify-button2">Email Support</button>
                    <button onClick={handleCallSupport} className="verify-button2">Text Support</button>
                </div>
            </div>
        </Container>
    );
};

export default HelpCenterScreen;
