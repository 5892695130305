import React, { useState, useEffect } from 'react';
import { load } from '@cashfreepayments/cashfree-js'; 
import '../Login/LoginScreen.css';
import { handleGetMembership, createOrder } from '../../../api/api';
import ToastMsg from '../common/ToastMsg.js';

const MembershipPlans = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedDeposit, setSelectedDeposit] = useState(null);
    const [plans, setPlans] = useState([]);
    const [cashfree, setCashfree] = useState(null); 

    useEffect(() => {
        load({ mode: "sandbox" })
            .then((cashfreeInstance) => {
                setCashfree(cashfreeInstance); 
            })
            .catch((error) => {
                console.error("Error loading Cashfree SDK:", error);
            });
    }, []);

    useEffect(() => {
        const fetchMembershipData = async () => {
            try {
                const response = await handleGetMembership();
                const data = response.data;
                console.log(data, "membership data");

                if (data && Array.isArray(data)) {
                    const groupedPlans = data.reduce((acc, plan) => {
                        const { id, membership_type, video_point, deposit_point, withdrawl_days } = plan;

                        if (!acc[membership_type]) {
                            acc[membership_type] = {
                                id, 
                                type: membership_type,
                                note: `Withdrawal in ${withdrawl_days} days`,
                                deposits: []
                            };
                        }

                        if (deposit_point !== "FREE") {
                            acc[membership_type].deposits.push({
                                amount: parseInt(deposit_point),
                                perVideo: parseInt(video_point),
                                membershipPlanId: id 
                            });
                        } else {
                            acc[membership_type].free = {
                                videoPoint: video_point,
                                depositPoint: deposit_point
                            };
                        }

                        return acc;
                    }, {});

                    setPlans(Object.values(groupedPlans));
                    console.log(Object.values(groupedPlans), "Plans data after grouping");
                    // ToastMsg.success('Membership data fetched successfully!'); 
                } else {
                    console.error('Invalid membership data format received.');
                    ToastMsg.error('Failed to fetch membership data. Please try again.');
                }
            } catch (error) {
                console.error('Error fetching membership data:', error);
                ToastMsg.error('Error fetching membership data.');
            }
        };

        fetchMembershipData();
    }, []);

    const createOrderApiFunction = (deposit, membership_plan_id, token) => {
        createOrder(deposit, membership_plan_id, token)
            .then((data) => {
                const paymentSessionId = data?.data?.payment_session_id;
                console.log('Order created successfully:', paymentSessionId);

                if (paymentSessionId) {
                    const handlePayment = () => {
                        if (cashfree) {
                            const checkoutOptions = {
                                paymentSessionId: paymentSessionId,
                                redirectTarget: "_self" 
                            };

                            cashfree.checkout(checkoutOptions)
                                .then((result) => {
                                    if (result.error) {
                                        console.log("Payment error: ", result.error);
                                    } else if (result.paymentDetails) {
                                        console.log("Payment completed: ", result.paymentDetails.paymentMessage);
                                    }
                                });
                        } else {
                            console.log("Cashfree SDK not initialized yet");
                        }
                    };

                    handlePayment();
                } else {
                    ToastMsg.error('Failed to retrieve payment session ID.');
                }
            })
            .catch((error) => {
                console.error('Error creating order:', error);
                ToastMsg.error('Error creating order. Please try again.');
            });
    };

    const handleDepositClick = (plan, deposit) => {
        console.log('Deposit clicked for:', plan, deposit);
        setSelectedPlan(plan); 
        setSelectedDeposit(deposit);
        setIsPopupVisible(true);
    };

    const handleConfirm = () => {
        const token = localStorage.getItem('access_token');
        const membershipPlanId = selectedDeposit.membershipPlanId; 
        console.log('Selected Plan ID:', membershipPlanId);
        createOrderApiFunction(selectedDeposit.amount, membershipPlanId, token);
        setIsPopupVisible(false);
    };

    return (
        <div className="membership-container">
            <div className='flexcenterf'>
                <span className="plans-title">Membership Plans for You</span>
                <div className="gradient-background"></div>
            </div>

            {plans.map((plan, index) => (
                <div key={index} className="plan">
                    <h4>
                        {plan.type} <span className="plan-note red">{plan.note}</span>
                    </h4>

                    {plan.deposits.length > 0 ? (
                        <div className="deposits">
                            {plan.deposits.map((deposit, depositIndex) => (
                                <div key={depositIndex} className="deposit-item" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span className="bullet" style={{ marginRight: "8px", display: "inline-block" }}>►</span>
                                        <span>
                                            Deposit <span className='green font60014'>₹{deposit.amount}</span> and get 
                                            <span className='green font60014'> ₹{deposit.perVideo} </span> per video.
                                        </span>
                                    </div>

                                    <div 
                                        className="deposit-button" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDepositClick(plan, deposit);
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Deposit
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : plan.free ? (
                        <div className="free-plan">
                            <span className="bullet">►</span>
                            <span>Get <span className='green font60014'> ₹{plan.free.videoPoint} </span> per video for free.</span>
                        </div>
                    ) : null}
                </div>
            ))}

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <h2>Confirm Payment</h2>
                        <p>Are you sure you want to proceed with the payment for {selectedPlan?.type} plan of ₹{selectedDeposit?.amount}?</p>
                        <button onClick={handleConfirm} className="confirm-button">Yes</button>
                        <button onClick={() => setIsPopupVisible(false)} className="cancel-button">No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MembershipPlans;
