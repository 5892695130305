import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import errorIcon from '../../../assets/images/error.png'; 
import Container from '../../container';

const PoliciesScreen = () => {
    const handleWatchVideo = () => {
        window.location.href = "/dashboard";
    }

    return (
        <Container>
            <div className="oops-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <div className="error-icon-container mt-20">
                    {/* <img src={errorIcon} alt="error" className="error-icon" /> */}
                </div>
                {/* <h2 className="oops-title">Oops!!</h2> */}
                <h2 className="terms-title">Privacy Policy</h2>
                <div className="instructions-container">
                    <p className="terms-instructions">
                        <strong>Data Collection:</strong> PaiseBnao collects personal information such as name, email, and phone number during registration to enhance user experience. We may also collect data about your activity within the app for analytical purposes.
                    </p>
                    <p className="terms-instructions">
                        <strong>Data Usage:</strong> The information collected is used to personalize content, improve services, and process payments. We do not share your personal data with third parties, except for payment processing and legal compliance.
                    </p>
                    <p className="terms-instructions">
                        <strong>Cookies and Tracking:</strong> PaiseBnao may use cookies to track user activity and preferences. This helps us optimize the platform experience, but users can choose to disable cookies in their browser settings.
                    </p>
                    <p className="terms-instructions">
                        <strong>Security Measures:</strong> We implement industry-standard security protocols to protect your data from unauthorized access. However, users are responsible for safeguarding their login credentials.
                    </p>
                    <p className="terms-instructions">
                        <strong>Data Retention:</strong> We retain personal data for as long as necessary to provide our services or as required by law. Users can request the deletion of their data by contacting support.
                    </p>
                    <p className="terms-instructions">
                        <strong>Changes to Policy:</strong> PaiseBnao reserves the right to modify this privacy policy at any time. Users will be notified of significant changes, and continued use of the app constitutes acceptance of the updated policy.
                    </p>
                    <p className="terms-instructions">
                        <strong>Conversion of Earnings:</strong> PaiseBnao reserves the right to convert rupees earned by users into points at any time. This means that users' rupees may be converted into a points-based system at the discretion of the company. The conversion rate and the way these points can be redeemed will be communicated when necessary. Users must acknowledge that the value of points may vary and agree to these terms by continuing to use the platform.
                    </p>
                </div>
            </div>
    
            <div onClick={handleWatchVideo} className="verify-button2">Continue Watching Videos</div>
        </Container>
    );
};

export default PoliciesScreen;
