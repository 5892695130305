import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import Container from '../../container';

const OopsScreen = () => {
    const handleWatchVideo= ()=>{
window.location.href="/dashboard"
    }
    return (
        <Container>
           <div className="oops-container">
    <div className="flex-row mt-10">
        <Header />
    </div>
    <div className="error-icon-container mt-20">
    </div>
    <div className="instructions-container">
        <p className="terms-instructions">
            Eligibility: To participate in PaiseBnao, users must be at least 18 years old and located within eligible regions where the app operates. Only one account is permitted per user. Users are required to provide accurate information during registration.
        </p>
        <p className="terms-instructions">
            Earning Rules: Users earn money by watching videos provided on the PaiseBnao platform. Each video has a predefined payout, and the time spent watching videos is tracked to ensure compliance. Any attempt to automate or fake video views will result in account suspension.
        </p>
        <p className="terms-instructions">
            Payment Terms: Earnings can be withdrawn once users reach a minimum balance of Rs. 500 in their PaiseBnao wallet. Payments are processed through UPI, bank transfer, or digital wallets. Users will be notified of successful transfers, and any processing fees for transactions will be clearly stated.
        </p>
        <p className="terms-instructions">
            Account Suspension/Termination: PaiseBnao reserves the right to suspend or terminate any account found to be violating the platform's rules, such as using bots, manipulating the system, or providing false information. Suspended accounts will forfeit any earned balance.
        </p>
        <p className="terms-instructions">
            Governing Law: The terms and conditions of PaiseBnao are governed by Indian law. Any disputes arising from the use of the platform will be subject to the jurisdiction of the courts in Noida, India.
        </p>
    </div>
</div>

    
            <div onClick={handleWatchVideo} className="verify-button2">Continue Watching Videos</div>
        </Container>
    );
};

export default OopsScreen;
