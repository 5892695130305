import React, { useEffect, useState } from 'react';
import { useFetcher, useLocation } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import referralImage from '../../../assets/images/referandearn.png'; 
import whatsappIcon from '../../../assets/images/what.png'; 
import instagramIcon from '../../../assets/images/insta.png'; 
import telegramIcon from '../../../assets/images/tele.png'; 
import shareIcon from '../../../assets/images/share.png'; 
import copyimg from '../../../assets/images/copy.png'; 
import Container from '../../container';

const ReferAndEarn = () => {
    const location = useLocation();
    const [referralId, setReferralId] = useState('');
    const [referralCount, setReferralCount] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');
    const [AmountWid, setAmountWid] = useState(0);
    const [isFaultVisible, setIsFaultVisible] = useState(false)
    
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralId || 'AbCdEfGhIjK');
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }

        // Clear the success message after a few seconds
        setTimeout(() => {
            setCopySuccess('');
        }, 2000);
    };
    
    function openNativeShare(referralId) {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this app, Payme!',
                text: `I'm sharing this app with you! Enroll now and instantly get referral Coins. Use my referral ID: ${referralId}.`,
                url: "http://paisebnao.com/"
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            alert('Your browser does not support the native share feature.');
        }
    }
    
    function shareOnSocialMedia(platform, referralId) {
        let url = '';
        const message = `I'm sharing this app with you! Enroll now and instantly get ₹500. Use my referral ID: \\${referralId}.`;
    
        switch(platform) {
            case 'whatsapp':
                url = `https://wa.me/9769335392?text=${encodeURIComponent(message)}`;
                break;
            case 'telegram':
                url = `https://t.me/share/url?url=${encodeURIComponent('https://example.com')}&text=${encodeURIComponent(message)}`;
                break;
            case 'instagram':
                url = 'https://www.instagram.com/yourprofile/';
                break;
            default:
                console.log('Unsupported platform');
                return;
        }
    
        window.open(url, '_blank', 'noopener noreferrer');
    }
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setAmountWid(params.get('points-earned'))
        setReferralId(params.get('referralId'));
        setReferralCount(params.get('referralCount'));
    }, [location]);

    return (
        <Container>
            <div className="refer-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                
                {isFaultVisible &&
                <div className="refer-status">
                    {referralCount <= 2  ? (
                        <>
                            To withdraw {AmountWid}, you need to refer 3 friends <span className="refer-count">{referralCount ?? 0}/3</span>
                        </>
                    ) : (
                        <>Congratulations! Your refer count is {referralCount}, eligible for {AmountWid} withdrawal.</>
                    )}
                </div>}

                <h2 className="refer-title">Refer & Earn</h2>
                <span className='referandshare'>Refer your Friend & Family and earh  ₹ 500 Each</span>
                <img src={referralImage} alt="Refer and Earn" className="referral-image" />
                <div className="refer-steps">
                    <div className="step">
                        <span className="step-number">01</span>
                        <p>Refer your friends.</p>
                    </div>
                    <div className="divider"></div>
                    <div className="step">
                        <span className="step-number">02</span>
                        <p>Let them log in with your referral code.</p>
                    </div>
                    <div className="divider"></div>
                    <div className="step">
                        <span className="step-number">03</span>
                        <p>Get {AmountWid} instantly.</p>
                    </div>
                </div>

                <div className="referral-code-container outlinediv">
                    <label className="referral-code-label">Your Referral Code</label>
                    <div className="referral-code-box">
                        <span className="referral-code">{referralId || 'AbCdEfGhIjK'}</span>
                        <div className="copy-button" onClick={copyToClipboard}>
                            {copySuccess ? <span className="copy-success">{copySuccess}</span> :<span>Tap to copy</span>}
                            <img className='ml-5' width={15} src={copyimg} />
                        </div>
                    </div>
                </div>
                <div className="social-invite-container outlinediv">
                    <label className="referral-code-label mb-20">Invite by Social Media</label>
                    <div className='flex-col-center' onClick={() => openNativeShare(referralId)}>
                            <img src={shareIcon} alt="Share" className="social-icon-share" />
                            <button className='white-refer mt-10 top-button refer-earn'>Refer Now</button>
                        </div>
                    {/* <div className="social-icons">
                        <div className='flex-col-center' onClick={() => shareOnSocialMedia('whatsapp')}>
                            <img src={whatsappIcon} alt="WhatsApp" className="social-icon" />
                            <span className='fade-colour mt-10'>WhatsApp</span>
                        </div>
                        <div className='flex-col-center' onClick={() => shareOnSocialMedia('instagram')}>
                            <img src={instagramIcon} alt="Instagram" className="social-icon" />
                            <span className='fade-colour mt-10'>Instagram</span>
                        </div>
                        <div className='flex-col-center' onClick={() => shareOnSocialMedia('telegram')}>
                            <img src={telegramIcon} alt="Telegram" className="social-icon" />
                            <span className='fade-colour mt-10'>Telegram</span>
                        </div>
                        <div className='flex-col-center' onClick={() => openNativeShare(referralId)}>
                            <img src={shareIcon} alt="Share" className="social-icon-share" />
                            <span className='fade-colour mt-10'>Share</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </Container>
    );
};

export default ReferAndEarn;
